<template>
  <div class="container">
    <div class="right">
      <div v-for="item,index in menus" class="menuItem" @click="toUrl(item.url)" :key="index">
        <span class="name">{{item.name}}</span>
        <span class="line" v-if="index !== menus.length - 1">|</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterMenu',
  data() {
    return {
      menus: [
        {
          name: '网站首页',
          url: '/',
          icon: 'icon iconfont icon-home'
        },
        {
          name: '成功案例',
          url: ''
        },
        {
          name: '国家政策',
          url: ''
        },
        {
          name: '新闻中心',
          url: ''
        },
        {
          name: '关于我们',
          url: ''
        },
        {
          name: '联系我们',
          url: ''
        }
      ]
    }
  },
  methods: {
    toUrl(url) {
      if (url) {
        this.$router.push(url)
      } else {
        this.$Message.info('板块维护中，敬请期待')
      }
    }
  }
}
</script>


<style lang="less" scoped>
.container {
  display: flex;
  height: 50px;
  color: #fff;
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(3, 148, 222);
    padding-left: 60px;
    .menuItem {
      i {
        font-size: 24px;
        vertical-align: bottom;
      }
      .name {
        cursor: pointer;
      }
      .line {
        padding: 0 50px;
      }
    }
  }
}
</style>