<template>
  <div class="home">
    <div class="column_1">
      <img :src="banner" />
      <div class="content">
        <div class="top">
          <div v-for="(item, key) in col_1.top" :class="key" :key="key">
            <span v-for="(ele, index) in item" :key="index">{{ ele }}</span>
          </div>
        </div>
        <div class="bottom">
          <ul class="list">
            <li v-for="(item, index) in col_1.bottom" :key="index">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="column_2">
      <div v-for="item,index in col_2" :class="`item ${index === col_2.length - 1 ? '' : 'not-last'}`" :key="index">
        <i v-if="item.icon" class="icon iconfont" :class="item.icon"></i>
        <img v-if="item.img" :src="item.img" alt="">
        <div class="name">
          <span>{{item.name}}</span>
          <span>{{item.name_en}}</span>
        </div>
      </div>
    </div>
    <div class="column_3">
      <img :src="col_3_bg" />
      <div class="wrap">
        <div class="title">
          <p><span>新能源</span>一站式服务商</p>
          <span>NEW ENERGY ONE-STOP SERVICE PROVIDER</span>
          <span>竭诚为客户提供新能源建筑节能一体化解决方案</span>
        </div>
        <div class="content">
          <div class="left">
            <div class="item" v-for="item,index in col_3" :key="index">
              <div class="subTitle">
                <div class="index">0{{index + 1}}</div>
                <span>{{item.name}}</span>
              </div>
              <span class="desc">{{item.content}}</span>
            </div>
          </div>
          <div class="right">
            <div class="chinamap"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="column_4">
      <img :src="col_4_bg" />
      <div class="left">
        <div class="top">
          <div class="title">
            <span>冉冉光电</span>
            <span>打造光伏产业优质品牌</span>
          </div>
          <span>主营新能源项目的研发、设计、销售、施工管理、运行维护和技术服务</span>
        </div>
        <div class="bottom">
          <i class="icon iconfont icon-phone-handle"></i>
          <span>服务咨询：</span>
          <span>{{info.phone}}</span>
        </div>
      </div>
      <img class="right" :src="col_4_right" />
    </div>
    <div class="column_5">
      <img :src="col_5_bg" />
      <div class="wrap">
        <div class="title">
          <span>为客户提供一站式新能源服务</span>
          <span>NEW ENERGY ONE-STOP SERVICE PROVIDER</span>
          <span>以客户为中心，以价值创造者为本，建设绿色家园</span>
        </div>
        <div class="list">
          <div class="caseItem" v-for="item,index in caseList" :key="index">
            <case-item :data="item"></case-item>
          </div>
          <div class="caseItem"></div>
        </div>
      </div>
    </div>
    <div class="column_6">
      <img :src="col_6_bg" />
      <div class="wrap">
        <div class="top">
          <span class="title">光伏储能电站服务流程</span>
          <span class="subTitle">PV ENERGY POWER STATION SERVICE PROCESS</span>
        </div>
        <div class="bottom">
          <div class="item" v-for="item,index in col_6" :key="index">
            <div class="iconWrap">
              <i class="icon iconfont" :class="item.icon"></i>
            </div>
            <div class="item_content">
              <span>{{item.title}}</span>
              <span>{{item.subTitle}}</span>
            </div>
            <span class="direct" v-if="index !== col_6.length - 1">>></span>
          </div>
        </div>
      </div>
    </div>
    <div class="column_7">
      <img :src="col_7_bg" />
      <div class="wrap">
        <div class="title">
          <p>冉冉光电-<span>六大优势</span></p>
          <span>RANRAN OPTOELECTRONICS-SIX ADVANTAGES</span>
          <span>以市场为导向、以质量求生存、以信誉求发展、以安全创效益</span>
        </div>
        <div class="content">
          <div class="pic">
            <img :src="col_7_center" />
          </div>
          <div class="item" v-for="item,index in col_7" :key="index" :class="index%2===0?'':'right'">
            <div class="itemTop">
              <div v-if="index%2===0" class="index">
                <span>0{{index + 1}}</span>
              </div>
              <span class="title">{{item.title}}</span>
              <div v-if="index%2!==0" class="index">
                <span>0{{index + 1}}</span>
              </div>
            </div>
            <div class="itemBottom" :style="{width:item.width}">
              <span>{{item.content}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column_8">
      <div class="top">
        <div class="left">
          <span class="title">{{info.shortName}}</span>
          <span class="subTitle">{{col_8.subTitle}}</span>
          <div class="content" v-html="info.introduce">
          </div>
        </div>
        <div class="right">
          <img :src="col_8_right" />
        </div>
      </div>
    </div>
    <footer-menu />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CaseItem from '@/components/pc/CaseItem'
import FooterMenu from '@/components/pc/FooterMenu'
export default {
  name: 'Home',
  components: { CaseItem, FooterMenu },
  data() {
    return {
      banner: require('@/assets/imgs/pc/banner.png'),
      col_3_bg: require('@/assets/imgs/pc/column_3_bg.png'),
      col_4_bg: require('@/assets/imgs/pc/column_4_bg.png'),
      col_4_right: require('@/assets/imgs/pc/column_4_right.png'),
      col_5_bg: require('@/assets/imgs/pc/column_5_bg.png'),
      col_6_bg: require('@/assets/imgs/pc/column_6_bg.png'),
      col_7_bg: require('@/assets/imgs/pc/column_7_bg.png'),
      col_7_center: require('@/assets/imgs/pc/column_7_center.png'),
      col_8_right: require('@/assets/imgs/pc/column_8_right.png'),
      col_1: {
        top: {
          left: ['一站式', '全生命周期管理'],
          right: ['新能源电站', '服务']
        },
        bottom: ['合作', '创新', '改变', '至善']
      },
      col_2: [
        {
          name: '高效',
          name_en: 'HIGH EFFICIENCY',
          icon: 'icon-clock'
        },
        {
          name: '安全',
          name_en: 'SAFETY',
          icon: 'icon-dunpai'
        },
        {
          name: '智能',
          name_en: 'INTELLECT',
          icon: 'icon-Sharingwuxianlanyagongxiang9'
        },
        {
          name: '快速',
          name_en: 'FAST',
          img: require('@/assets/imgs/pc/lightning.png')
        },
        {
          name: '专业',
          name_en: 'PROFESSION',
          icon: 'icon-zhuanyefuwu00'
        }
      ],
      col_3: [
        {
          name: '新能源工程总承包',
          content: '公司拥有电力行业工程设计资质、工程勘查资质和专业的设计团队，为客户提供相应等级的变电工程、送电工程、风力发电、新能源发电工程项目的前期规划、可研、设计及EPC总承包'
        },
        {
          name: '光伏电站投资、建设、工程咨询',
          content: '依托经验丰富的现场运维团队，以电站安全运行规程、规范为基础，通过巡检、一般性消缺、预防性维护、周期性维护以及定期的设备性能检测、试验等手段，保证设备安全可靠运行，降低故障率'
        },
        {
          name: '光伏技术服务',
          content: '公司团队由来自电力设计和电力建设等行业的全方位专家人才组成，具有丰富的可研、设计、采购、施工、安装、调试和运营管理经验，可帮助客户解决光伏相关问题'
        }
      ],
      col_6: [
        {
          title: '咨询客服',
          subTitle: '13584301858',
          icon: 'icon-phone-handle'
        },
        {
          title: '规划设计',
          subTitle: '按需制定方案',
          icon: 'icon-icon4'
        },
        {
          title: '签订合同',
          subTitle: '交付定金',
          icon: 'icon-shenhe_gaizhang'
        },
        {
          title: '快速安装',
          subTitle: '使用培训',
          icon: 'icon-web-icon-'
        },
        {
          title: '维护保修',
          subTitle: '配件供应',
          icon: 'icon-baoxiu'
        }
      ],
      col_7: [
        {
          title: '行业经验丰富',
          content: '冉冉光电科技有限公司是一家专注于太阳能新能源类研发与安装的公司。冉冉拥有专业的研发及技术团队、超强的合作伙伴、精准的企业定位以及全方位的售后服务',
          width: '64%'
        },
        {
          title: '专业的研发团队',
          content: '公司始终专注于新能源发电领域，坚持以市场需求为导向、以技术创新作为企业发展的动力源，培育了一支研发经验丰富、自主创新能力较强的专业研发队伍',
          width: '64%'
        },
        {
          title: '行业应用广泛',
          content: '光伏储能系统被广泛应用于农村屋顶、城市别墅、商业建筑、扶贫山区等，为您带来持续、稳定的收益保障，汇聚光伏能源，建设绿色家园',
          width: '50%'
        },
        {
          title: '超强的合作伙伴',
          content: '公司总经理曾就职于国家电网，自公司成立以来，多次与天合光能股份有限公司、阳光电源股份有限公司、上海聚合新能源公司等光伏产业龙头企业合作，与国内多家电力设备、光伏、风电等公司建立了长久的合作伙伴关系',
          width: '50%'
        },
        {
          title: '精准的企业定位',
          content: '本公司秉承让出资人放心、客户满意、利益相关方共赢、社会赞誉、员工幸福的企业宗旨，始终致力于降低度电成本的研发工作，让客户用最低的价格享受最优质的服务，以客户为中心，以价值创造者为本',
          width: '64%'
        },
        {
          title: '全方位的售后服务',
          content: '冉冉光电科技是一家专注于太阳能新能源类研发与安装的公司，冉冉拥有专业的研发及技术团队、超强的合作伙伴、精准的企业定位以及全方位的售后服务',
          width: '64%'
        }
      ],
      col_8: {
        subTitle: '---竭诚为客户提供新能源建筑节能一体化解决方案'
      }
    }
  },
  computed: {
    ...mapState(['info', 'caseList'])
  }
}
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .column_1 {
    height: 600px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .content {
      position: absolute;
      top: 80px;
      right: 20%;
      & > div {
        display: flex;
      }
      .top {
        align-items: center;
        & > div {
          display: flex;
          flex-direction: column;
        }
        .left {
          align-items: center;
          span:nth-child(1) {
            color: rgb(3, 148, 222);
            font: bold 64px/68px normal;
            letter-spacing: 6px;
          }
          span:nth-child(2) {
            font-size: 24px;
            letter-spacing: 4px;
          }
        }
        .right {
          padding-left: 20px;
          span {
            font-size: 24px;
            letter-spacing: 4px;
          }
          span:nth-child(2) {
            font: bold 64px/68px normal;
            letter-spacing: 6px;
          }
        }
      }
      .bottom {
        flex-direction: column;
        margin-top: 20px;
        &::before,
        &::after {
          content: '';
          display: block;
          height: 1px;
          width: 100%;
          background: linear-gradient(
            to right,
            transparent,
            #2c3e50,
            transparent
          );
        }
        .list {
          width: 100%;
          font-size: 24px;
          display: flex;
          justify-content: space-around;
          padding: 8px 0;
          margin: 0;
          li:nth-child(1) {
            list-style: none;
            padding-left: 0;
          }
        }
      }
    }
  }
  .column_2 {
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: -60px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    .not-last::after {
      content: '';
      display: block;
      width: 1px;
      height: 50px;
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      right: 0;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      box-sizing: border-box;
      position: relative;
      &:first-child {
        justify-content: flex-start;
        padding-left: 26px;
      }
      i {
        font-size: 40px;
        padding-right: 10px;
      }
      img {
        padding-right: 10px;
      }
      .name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        span:nth-child(1) {
          font-size: 24px;
          margin-top: -14px;
          letter-spacing: 10px;
        }
        span:nth-child(2) {
          display: block;
          color: rgba(0, 0, 0, 0.2);
          transform: scale(0.7);
          transform-origin: 0;
          width: 152px;
          position: absolute;
          left: 0;
          top: 14px;
        }
      }
    }
  }
  .column_3 {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 50px 10%;
    padding-bottom: 0;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .wrap {
      position: relative;
      z-index: 1;
      width: 80%;
      max-width: 1280px;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin: 0;
          font-size: 30px;
          line-height: 50px;
          font-weight: bold;
          letter-spacing: 4px;
          span {
            color: rgb(3, 148, 222);
          }
        }
        span:nth-child(2) {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
          &::before {
            content: '';
            display: inline-block;
            height: 1px;
            width: 50px;
            background: rgba(0, 0, 0, 0.2);
            vertical-align: super;
            transform: translateX(-50px);
          }
          &::after {
            content: '';
            display: inline-block;
            height: 1px;
            width: 50px;
            background: rgba(0, 0, 0, 0.2);
            vertical-align: super;
            transform: translateX(50px);
          }
        }
        span:nth-child(3) {
          line-height: 40px;
        }
      }
      .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        .left {
          width: 40%;
          .item {
            margin-bottom: 20px;
            .subTitle {
              display: flex;
              padding: 10px 0;
              .index {
                display: block;
                width: 22px;
                height: 40px;
                background: rgb(3, 148, 222);
                line-height: 40px;
                color: #fff;
                text-align: center;
                font-weight: bold;
                position: relative;
                &::before {
                  content: '';
                  display: block;
                  border: 20px solid transparent;
                  border-right: 12px solid rgb(3, 148, 222);
                  position: absolute;
                  top: 0;
                  left: -32px;
                }
                &::after {
                  content: '';
                  display: block;
                  border: 20px solid transparent;
                  border-left: 12px solid rgb(3, 148, 222);
                  transform: translateX(2px);
                  position: absolute;
                  top: 0;
                  left: 20px;
                  box-shadow: -1px 0 0px 0 rgb(3, 148, 222);
                }
              }
              span {
                font-size: 18px;
                font-weight: bold;
                line-height: 40px;
                margin-left: 24px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                transform: translateY(-5px);
                &::after {
                  content: '';
                  display: block;
                  width: 5px;
                  height: 5px;
                  border: 1px solid rgba(0, 0, 0, 0.2);
                  border-radius: 50%;
                  position: absolute;
                  right: 0;
                  bottom: -3px;
                  right: -20px;
                }
              }
            }
            .desc {
              font-size: 12px;
            }
          }
        }
        .right {
          width: 60%;
          height: 500px;
        }
      }
    }
  }
  .column_4 {
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 50px 10%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .left {
      position: relative;
      z-index: 1;
      width: 80%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      .top {
        padding-left: 20px;
        border-left: 2px solid #fff;
        .title {
          span:nth-child(1) {
            font-size: 24px;
            font-weight: bold;
            padding-right: 14px;
          }
          span:nth-child(2) {
            font-size: 16px;
            font-weight: bold;
          }
        }
        & > span {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .bottom {
        margin-top: 20px;
        .icon {
          font-size: 20px;
        }
        span:nth-child(2) {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          padding-left: 4px;
        }
        span:nth-child(3) {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .right {
      position: absolute;
      right: 0;
      top: -20px;
      left: auto;
      width: 600px;
      height: 300px;
      z-index: 2;
    }
  }
  .column_5 {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 50px 10%;
    min-height: 500px;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .wrap {
      position: relative;
      z-index: 1;
      width: 80%;
      max-width: 1280px;
      padding: 20px;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        span:nth-child(1) {
          font-size: 30px;
          font-weight: bold;
        }
        span:nth-child(2) {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
          &::before {
            content: '';
            display: inline-block;
            height: 1px;
            width: 50px;
            background: rgba(0, 0, 0, 0.2);
            vertical-align: super;
            transform: translateX(-50px);
          }
          &::after {
            content: '';
            display: inline-block;
            height: 1px;
            width: 50px;
            background: rgba(0, 0, 0, 0.2);
            vertical-align: super;
            transform: translateX(50px);
          }
        }
        span:nth-child(3) {
          line-height: 40px;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 20px;
        .caseItem {
          width: 33%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .column_6 {
    height: 400px;
    position: relative;
    padding: 0 10%;
    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .wrap {
      position: relative;
      z-index: 1;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px;
        .title {
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 6px;
        }
        .subTitle {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          &::before {
            content: '';
            display: inline-block;
            height: 1px;
            width: 50px;
            background: rgba(255, 255, 255, 0.2);
            vertical-align: super;
            transform: translateX(-50px);
          }
          &::after {
            content: '';
            display: inline-block;
            height: 1px;
            width: 50px;
            background: rgba(255, 255, 255, 0.2);
            vertical-align: super;
            transform: translateX(50px);
          }
        }
      }
      .bottom {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 80%;
        max-width: 1000px;
        .item {
          width: 120px;
          height: 240px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 4px 4px 0 0;
          transition: all 0.3s linear;
          position: relative;
          .direct {
            position: absolute;
            right: -50px;
            top: 36px;
          }
          .iconWrap {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            margin: 20px 10px;
            .icon {
              font-size: 28px;
            }
          }
          .item_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            span:nth-child(1) {
              font-size: 20px;
              font-weight: bold;
            }
          }
          &:hover {
            background: #fff;
            .iconWrap {
              background: rgb(3, 148, 222);
              .icon {
                color: #fff;
              }
            }
            .item_content {
              color: rgba(0, 0, 0, 1);
            }
          }
        }
      }
    }
  }
  .column_7 {
    position: relative;
    padding: 0 10% 120px;
    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .wrap {
      position: relative;
      z-index: 1;
      padding: 0 6%;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 10%;
        p {
          margin: 0;
          font-size: 30px;
          line-height: 50px;
          font-weight: bold;
          letter-spacing: 4px;
          span {
            color: rgb(3, 148, 222);
          }
        }
        span:nth-child(2) {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
          &::before {
            content: '';
            display: inline-block;
            height: 1px;
            width: 50px;
            background: rgba(0, 0, 0, 0.2);
            vertical-align: super;
            transform: translateX(-50px);
          }
          &::after {
            content: '';
            display: inline-block;
            height: 1px;
            width: 50px;
            background: rgba(0, 0, 0, 0.2);
            vertical-align: super;
            transform: translateX(50px);
          }
        }
        span:nth-child(3) {
          line-height: 40px;
        }
      }
      .content {
        display: flex;
        position: relative;
        justify-content: space-between;
        flex-wrap: wrap;
        .pic {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          pointer-events: none;
          img {
            position: relative;
            width: 580px;
            height: 500px;
            padding: 30px;
          }
        }
        .right {
          align-items: flex-end;
          text-align: right;
        }
        .item {
          width: 50%;
          padding-bottom: 80px;
          display: flex;
          flex-direction: column;
          .itemTop {
            display: flex;
            .index {
              width: 30px;
              height: 30px;
              border: 2px solid rgb(3, 148, 222);
              display: flex;
              justify-content: center;
              align-items: center;
              & > span {
                border-bottom: 2px solid rgb(3, 148, 222);
                line-height: 16px;
                color: rgb(3, 148, 222);
                font-weight: bold;
              }
            }
            .title {
              height: 20px;
              padding: 8px 0 0;
              font-weight: bold;
            }
          }
          .itemBottom {
            padding-top: 10px;
            width: 60%;
            font-size: 12px;
          }
        }
      }
    }
  }
  .column_8 {
    padding: 50px 10%;
    display: flex;
    justify-content: center;
    position: relative;
    .top {
      width: 88%;
      .left {
        display: flex;
        flex-direction: column;
        width: 50%;
        .title {
          color: rgb(3, 148, 222);
          font-size: 20px;
          font-weight: bold;
          line-height: 40px;
        }
        .subTitle {
          font-weight: bold;
          padding-bottom: 20px;
        }
        .content {
          font-size: 12px;
          /deep/ p {
            text-indent: 20px;
            span {
              color: rgb(3, 148, 222);
              cursor: pointer;
              word-break: keep-all;
            }
          }
        }
      }
      .right {
        position: absolute;
        right: 0;
        top: -80px;
        img {
          width: 600px;
        }
      }
    }
  }
}
</style>