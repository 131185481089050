<template>
  <div class="container">
    <div class="img">
      <img :src="data.images[0]" alt="">
      <span>无图片</span>
    </div>
    <div class="info">
      <span>{{data.type}}</span>
      <span>装机容量：{{data.volume}}MW</span>
      <span>安装地址：{{data.address}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseItem',
  props: ['data']
}
</script>

<style lang="less" scoped>
.container {
  padding: 14px 5px;
  .img {
    width: 240px;
    height: 160px;
    border: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.2s linear;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    span:nth-child(1) {
      font-weight: bold;
      font-size: 16px;
    }
    span:nth-child(2) {
      color: rgb(4, 146, 219);
      font-size: 12px;
      line-height: 28px;
    }
    span:nth-child(3) {
      font-size: 12px;
    }
  }
}
</style>